body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.alert-button {
  cursor: pointer;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;

  &:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  &:focus {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  &.active {
    &:hover {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
    }
    &:focus {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
    }
  }
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;

  &:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  &:focus {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  &.active {
    &:hover {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;
    }
    &:focus {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;
    }
  }
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;

  &:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  &:focus {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  &.active {
    &:hover {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
    }
    &:focus {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
    }
  }
}

.btn {
  border: 0px;
  box-shadow: none;
  &:hover {
    border: 0px;
    box-shadow: none;
  }
  &:focus {
    border: 0px;
    box-shadow: none;
  }
  &.active {
    &:hover {
      border: 0px;
      box-shadow: none;
    }
    &:focus {
      border: 0px;
      box-shadow: none;
    }
  }
}
